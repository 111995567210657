import React, { useState, useEffect } from "react";
import moment from "moment";
import { Row, Table,  Col, DatePicker, Button, notification, Select, Form} from "antd";
import { getRegisteredTitlesApi, getSuppliersHistoryApi, getXlsxDonwloadApiApi, getFinancialTitlesApi} from "../services/supplierApi";
import { convertFloatDotSeperated } from "../helpers/common";
import '../../node_modules/moment/locale/tr';
import locale from '../../node_modules/antd/es/date-picker/locale/tr_TR';


function SupplierTransactions() {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [buyerRiskHistories, setBuyerRiskHistories] = useState<any>([]); 
  const [registeredSuppliers, setRegisteredSuppliers] = useState<any>([]);
  const [financialTitles, setFinancialTitles] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [supplierTitleId,setSupplierTitleId] = useState('');
  const [financialTitleId,setFinancialTitleId] = useState('');
  const [title,setTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [form] = Form.useForm();
 
  const getRegisteredTitles = async () => {
    try {
       const response: any = await getRegisteredTitlesApi();
      if (response) {
        setRegisteredSuppliers(response.data.registeredSuppliers);
      } 
    } catch (e:any) {
      console.log(e)      
    }
  };

  const getFinancialTitles = async () => {
    try {
       const response: any = await getFinancialTitlesApi();
      if (response) {
        setFinancialTitles(response.data.financialInstitutions);
      } 
    } catch (e:any) {
      console.log(e)      
    }
  };


  const getSuppliersHistory = async (startDate:string, finishDate: string) => {
    try {
        setLoading(true)
        const response: any = await getSuppliersHistoryApi(supplierTitleId,financialTitleId, startDate, finishDate, pageNumber, pageSize);
      if (response) {
        setBuyerRiskHistories(response.data.buyerRiskHistories.data);
        setTotalDataCount(response.data.buyerRiskHistories.totalDataCount)
        setLoading(false)
      } 
    } catch (e:any) {
      setLoading(false)
      console.log(e)      
    }
  };

   const  disabledDate = (current: any) => {  
    return (
      (new Date(current).getDay() === 0 ||
      new Date(current).getDay() === 6)
    );
  }
 

  const handleChange = (value: string) => {
    setTitle('');
    if(value){
      setSupplierTitleId(value);
      const supTitle:any [] = registeredSuppliers.filter((item:any) => item.id === value)
      setTitle(supTitle[0].supplierTitle)
      form.resetFields();
      setShow(false);
    }
  };

  const handleChangeFinancialTitles = (value: string) => {
    if(value){
      setFinancialTitleId(value);
      form.resetFields();
      setShow(false);
    }
  };

  const onchangeValue = async (values: any) => {   
    setStartDate(values.startDate !== '' ? moment(values.startDate).format('YYYY-MM-DD') : startDate);
    setEndDate(values.endDate !== '' ? moment(values.endDate).format('YYYY-MM-DD') : endDate)
    if(supplierTitleId !== ''){
      if(values.startDate !== undefined){
        getSuppliersHistory( moment(values.startDate).format('YYYY-MM-DD'),  values.endDate === undefined ? '' : moment(values.endDate).format('YYYY-MM-DD'));
        setShow(true);
      }else{
        notification.warning({
          message: `Başlangınç tarihi zorunlu seçilmeli.`,
        });
      }
    }else{
      notification.warning({
        message: `Tedarikçi seçimi yapmalısınız.`,
      });
    }
    // eslint-disable-next-line no-console
  };

  const xlsxDonwload = async () => {
    setLoading(true)
    try{
      const response:any = await getXlsxDonwloadApiApi(supplierTitleId, financialTitleId, startDate, endDate, pageNumber, pageSize);
      if (response) {
        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ${encodeURIComponent(response?.data?.xlsxFileReport)}` ;
        link.setAttribute('download', `${title} Tedarikçi Hareketleri.xlsx`); 
      
        link.style.display = 'none';
        document.body.appendChild(link);      
        link.click();      
        document.body.removeChild(link);
        setLoading(false)
      }
    }catch(e:any){ }
  }

  const tableCols: any = [
    {
      title: "İşlem Tarihi",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 150,
      fixed: 'left',
      render: (value: string) => {
        return moment(value).format("DD-MM-YYYY");
       }
    },
    {
      title: "Kredi Kullanımı",
      key: "transactionAmount",
      align: 'center',
      width: 150,
      render: ( row:any) => {
        return row.transactionType === 1 ? `${convertFloatDotSeperated(row.transactionAmount)} TL`  : '';
       }
    },
    {
      title: "Fatura Tahsilatı",
      key: "transactionAmount",
      align: 'center',
      width: 150,
      render: (row: any) => {
        return row.transactionType === 2 ? `${convertFloatDotSeperated(row.transactionAmount)} TL` : '';
       }
    },
    {
      title: "Risk Bakiyesi",
      dataIndex: "risk",
      key: "risk",
      align: 'center',
      width: 150,
      render: (value:any) => {
        return `${convertFloatDotSeperated(value)} TL` 
       }
    },
    {
      title: "Açıklama",
      dataIndex: "description",
      key: "description",
      align: 'left',
    },
  ];
  
  const handleShowSize = (current: any, size: any) => {
    setPageNumber(current);
    setPageSize(size);
  };
  

  useEffect(() => {
    if(supplierTitleId){
      getSuppliersHistory( startDate, endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize]);

  useEffect(() => {
    getRegisteredTitles();
    getFinancialTitles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Row style={{ marginTop: 50 }}>
        <Col span={24}>
          <h3 className="page-title">Tedarikçi Hareketleri İzleme</h3>
        </Col>
      </Row>
      <Row  >
          <Col style={{ margin: '30px 0 20px' }}>
            <p>Lütfen haraketlerini görüntülemek istediğiniz tedarikçiyi seçiniz.</p>
            <div style={{textAlign:'left'}}>
              <Select
                defaultValue="Lütfen seçim yapınız."
                style={{ width: 400 }}
                onChange={handleChange}
                options={ registeredSuppliers && registeredSuppliers.map((item:any) => {
                  return(
                      { value: item.id, label: item.supplierTitle}
                    )
                })
                }
              />
            </div> 
          </Col>
      </Row>
      <Row style={{marginBottom:'30px'}} >
          <Col style={{ margin: '0 0 20px' }}>
            <p>Lütfen seçmiş olduğunuz tedarikçinin hareketlerini görüntülemek istediğiniz finans kurumunu seçiniz.</p>
            <div style={{textAlign:'left'}}>
              <Select
                defaultValue="Lütfen seçim yapınız."
                style={{ width: 400 }}
                onChange={handleChangeFinancialTitles}
                options={ financialTitles && financialTitles.map((item:any) => {
                  return(
                      { value: item.id, label: item.name}
                    )
                })
                }
              />
            </div> 
          </Col>
      </Row>
      <Row>
          <Col >
            <Form
              form={form}
               name="filter-box"
               onFinish={onchangeValue}
               autoComplete="off"
               labelCol={{
                 span: 8,
               }}
               size = "large"
               style={{
                 minWidth: 400,
               }}
            >
              <div className='filter-row suppliers-movie' style={{alignItems:'center'}}>
                <div>
                    <span style={{display:'flex', alignItems:'center', paddingLeft:'1px'}}>Başlangıç Tarihi<pre style={{marginBottom:'0', color:'#f00', fontSize:'11px'}}> *</pre></span>
                    <Form.Item
                      name='startDate'
                    >
                      <DatePicker  locale={locale} format='DD/MM/YYYY'   placeholder='Tarih Seçiniz'   disabledDate={disabledDate}  />
                    </Form.Item>                  
                </div>
                <div>
                    <span style={{paddingLeft:'1px'}}>Bitiş Tarihi</span>
                    <Form.Item
                      name='endDate'
                    >
                      <DatePicker locale={locale} format='DD/MM/YYYY'   placeholder='Tarih Seçiniz'  disabledDate={disabledDate} />
                    </Form.Item>                  
                </div>
                <div>
                  <Form.Item style={{marginBottom:'0'}}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}                
                    >Filtrele
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Col>
        </Row>  
      {
        show ?
        (
          <>
            <Row  style={{justifyContent:'end', marginBottom:'20px'}}>
              <Button type="primary" size="large" onClick={xlsxDonwload} loading={loading} disabled={buyerRiskHistories.length < 1}>Excel İndir</Button>
            </Row>
            <Row>
            <Col span={24} style={{ margin: "0px auto" }}>
                <Table
                  rowKey="invoiceNumber"
                  loading={loading}
                  dataSource={buyerRiskHistories}
                  columns={tableCols}
                  
                  pagination={{
                    position: ["topRight", "none" as any],
                    showLessItems: true,
                    showSizeChanger: true,
                    pageSize,
                    total: totalDataCount,
                    onChange: handleShowSize,
                    
                  }}
                  scroll={{
                    x: 1200,
                  }}
                />
              
            </Col>
            </Row>
          </>
        ):
        (
          ""
        )
      }
      
    </main>
  );
}

export default SupplierTransactions;
