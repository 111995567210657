/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * This represents some generic auth provider API, like Firebase.
 */
const fakeAuthProvider = {
  isAuthenticated: false,
  signin(callback: VoidFunction) {
      fakeAuthProvider.isAuthenticated = true;
      setTimeout(callback, 100); // fake async
  },
  signout(callback: VoidFunction) {
      fakeAuthProvider.isAuthenticated = false;
      setTimeout(callback, 100);
  },
};
  
const urls = {
  login: '/login',
  passwordCreate: '/password/create',
  passwordUpdate: '/password/update',
  passwordReset: '/password/forget',
  forgetPassword: '/forgetpassword',
  twoFactor: '/twoFactor',
  home: '/',
  approvalReq: '/details',
  uploadInvoice: '/fatura-yukle',
  deleteInvoice: '/fatura-silme',
  updateMaturityInvoice: '/fatura-vade-guncelle',
  billStatus: '/fatura-durumu',
  invoiceAssigned: '/temlik-fatura',
  invoiceDue: '/vadesi-fatura',
  mySupplier: '/limit-bolustur',
  supplierLimits: '/tedarikci-limitleri',
  dashboard: '/anasayfa',
  myAccount: '/hesabım',
  supplierTransactions: '/tedarikci-hareketleri',
  passwordChange: '/password-change',
  allTransactions:'/fatura-hareketleri-izleme',
  supplierUsageReport:'/tedarikci-kullanim-raporu',


  allMustahsiller:'/tum-emustahsiller',
  invoiceAssignedMustahsil:'/temlik-mustahsil',
  invoiceDuesMustahsil:'/vadesi-mustahsil',
  supplierTransactionsMustahsil:'/ciftci-hareketleri',
  allTransactionsMustahsil:'/emustahsil-hareketleri-izleme',
  agriculturistLimit: '/ciftci-limitleri',
  myAgriculturist: '/ciftci-limit-bolustur',
  uploadMustahsil: '/emustahsil-yukle',
  deleteAgriculturist: '/emustahsil-silme',
  updateMaturityMustahsil: '/emustahsil-vade-guncelle',
};

export { fakeAuthProvider, urls };
  