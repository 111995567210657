import React,{useEffect, useState} from "react";
import {Row, Col, Collapse} from "antd";
import { useDispatch } from "react-redux";
import {setUserData} from "../../reducers/userSlice";
import moment from "moment";
import { FileDoneOutlined, UserOutlined,PullRequestOutlined,RollbackOutlined,RetweetOutlined,CheckOutlined } from "@ant-design/icons";
import {getDashboardSummaryApi, getUpComingDueInvoicesApi, getRiskLimitApi, getUsedInvoicesApi, getUpcomingPayDateApi} from '../../services/dashboardApi';
import {  convertFloatDotSeperated } from "../../helpers/common";
import { BarChart, Bar,  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { LocalStorageService } from "../../helpers/LocalStorage";

const { Panel } = Collapse;


function Dashboard() {
    const userBuyerType =  LocalStorageService.getBuyerType() === 'TEDARIKCI_ALICISI';
    const [dashboradSummary, setDashboradSummary] = useState<any>({
        activatedSuppliersCount : '',
        allSuppliersCount :'',
        assignedInvoicesCount :'',
        assignedInvoicesSum:'',
        invoicesCount :'',
        invoicesOnProcessCount :'',
        invoicesOnProcessSum :'',
        invoicesSum :'',
        prePaidInvoicesCount :'',
        prePaidInvoicesSum :'',
        rejectedInvoicesCount :'',
        rejectedInvoicesSum :'',
    })
    const [ dueInvoices, setDueInvoices] = useState<any>([]);
    const [ riskLimit, setRiskLimit] = useState<any>([]);
    const [ usedInvoices, setUsedInvoices] = useState<any>([]);
    const [ upcomingPayDate, setUpcomingPayDate] = useState<any>([]);
    const dispatch = useDispatch();

    const getDashboardSummary = async() => {
        try{
            const response = await getDashboardSummaryApi();
            if(response){
                const dashboradSummaryData = response.data.dashboradSummary;
                setDashboradSummary(
                    {
                        activatedSuppliersCount : dashboradSummaryData.activatedSuppliersCount,
                        allSuppliersCount :dashboradSummaryData.allSuppliersCount,
                        assignedInvoicesCount :dashboradSummaryData.assignedInvoicesCount,
                        assignedInvoicesSum:dashboradSummaryData.assignedInvoicesSum,
                        invoicesCount :dashboradSummaryData.invoicesCount,
                        invoicesOnProcessCount :dashboradSummaryData.invoicesOnProcessCount,
                        invoicesOnProcessSum :dashboradSummaryData.invoicesOnProcessSum,
                        invoicesSum :dashboradSummaryData.invoicesSum,
                        prePaidInvoicesCount :dashboradSummaryData.prePaidInvoicesCount,
                        prePaidInvoicesSum :dashboradSummaryData.prePaidInvoicesSum,
                        rejectedInvoicesCount :dashboradSummaryData.rejectedInvoicesCount,
                        rejectedInvoicesSum :dashboradSummaryData.rejectedInvoicesSum,
                    }
                )
            }

        }catch(e){
            console.log(e)
        }
    }

    const CustomTooltipComingDue = ({ active, label, payload }: any) => {
        if( active ){
          return (
            <div className="custom-tooltip">
                <p className="label">{`${label} `}</p>
                <p className="label">{` Vadesi Gelen: ${convertFloatDotSeperated(payload[0].value) + ' ₺'}`}</p>
            </div>
          );
        }
        return null;
      }

    const getUpComingDueInvoices = async() => {
        try{
            const response = await getUpComingDueInvoicesApi();
            if(response){
                let newData: any = [];
                response.data.upcomingDueDateInvoices.reverse().map((item:any) => {
                    return  newData.push({ 
                        name :  moment(item.date).format('MM-YYYY'),
                        'Vadesi Gelen' :item.amount ,
                        })                
                })
                setDueInvoices(newData)
            }

        }catch(e){
            console.log(e)
        }
    }

    const getRiskLimit = async() => {
        try{
            const response = await getRiskLimitApi();
            if(response){
                setRiskLimit(response.data.dashboardRiskAndLimits)
            }
        }catch(e){
            console.log(e)
        }
    }

    const CustomTooltipInvoices = ({ active, label, payload }: any) => {
        if( active ){
          return (
            <div className="custom-tooltip">
                <p className="label">{`${label} `}</p>
                <p className="label">{` Ödenen: ${convertFloatDotSeperated(payload[0].value) + ' ₺'}`}</p>
                <p className="label">{` Yüklenen: ${convertFloatDotSeperated(payload[1].value) + ' ₺'}`}</p>
            </div>
          );
        }
        return null;
      }

    const getUsedInvoices = async() => {
        try{
            const response = await getUsedInvoicesApi();        
            if(response){
                let newData: any = [];
                response.data.dashboardUsedInvoices.reverse().map((item:any) => {
                    return  newData.push({ 
                        namee :  moment(item.date).format('MM-YYYY'),
                        'Ödenen' : item.paidAmount,
                        'Yüklenen' : item.totalAmount,
                        'y':1000
                        })                
                })
                setUsedInvoices(newData)
            }

        }catch(e){
            console.log(e)
        }
    }

    const getUpcomingPayDate = async() => {       
        try{
            const response = await getUpcomingPayDateApi();
            if(response){
                setUpcomingPayDate( response.data.pagedResult.data)
            }
        }catch(e){
            console.log(e)
        }
        
    }


    useEffect(()=>{
        getDashboardSummary();
        getUpComingDueInvoices();
        getRiskLimit();
        getUsedInvoices();
        getUpcomingPayDate();
        dispatch(setUserData(' ')); 
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])

  return (
    <main>
        <div  className="dashboard">
            <Row>
                <Col span={12} style={{ margin: "0px auto" }}>
                    <h3 className="title">Özet Bilgiler</h3>
                    <div className="sum-information">
                        <div className="box box1">
                            <div className="icon-box">
                                <UserOutlined></UserOutlined>
                            </div>
                            <span className="txt1">{userBuyerType ? 'Tedarikçi Sayısı' : 'Çiftçi Sayısı'} </span>
                            <span className="txt4">{dashboradSummary.allSuppliersCount} </span>
                            <span className="txt3">{dashboradSummary.activatedSuppliersCount} Aktif</span>
                        </div>
                        <div className="box box3">
                            <div className="icon-box">
                                <FileDoneOutlined></FileDoneOutlined>
                            </div>
                            <span className="txt1">Yüklenen Fatura</span>
                            <span className="txt2">{convertFloatDotSeperated(dashboradSummary.invoicesSum)} ₺</span>
                            <span className="txt3">{dashboradSummary.invoicesCount} Adet</span>
                        </div>
                        <div className="box box4">
                            <div className="icon-box">
                                <PullRequestOutlined></PullRequestOutlined>
                            </div>
                            <span className="txt1">İskonto Talebi</span>
                            <span className="txt2">{convertFloatDotSeperated(dashboradSummary.invoicesOnProcessSum)} ₺</span>
                            <span className="txt3">{dashboradSummary.invoicesOnProcessCount} Adet</span>
                        </div>
                        <div className="box box5">
                            <div className="icon-box">
                                <RollbackOutlined></RollbackOutlined>
                            </div>
                            <span className="txt1">Reddedilen</span>
                            <span className="txt2">{convertFloatDotSeperated(dashboradSummary.rejectedInvoicesSum)} ₺</span>
                            <span className="txt3">{dashboradSummary.rejectedInvoicesCount} Adet</span>
                        </div>
                        <div className="box box6">
                            <div className="icon-box">
                                <RetweetOutlined></RetweetOutlined>
                            </div>
                            <span className="txt1">Temlik Edilen</span>
                            <span className="txt2">{convertFloatDotSeperated(dashboradSummary.assignedInvoicesSum)} ₺</span>
                            <span className="txt3">{dashboradSummary.assignedInvoicesCount} Adet</span>
                        </div>
                        <div className="box box7">
                            <div className="icon-box">
                                <CheckOutlined></CheckOutlined>
                            </div>
                            <span className="txt1">Ön Ödeme Yapılan</span>
                            <span className="txt2">{convertFloatDotSeperated(dashboradSummary.prePaidInvoicesSum)} ₺</span>
                            <span className="txt3">{dashboradSummary.prePaidInvoicesCount} Adet</span>
                        </div>
                    </div>
                </Col>  
                <Col span={12} style={{ margin: "0px auto" }}>
                    <h3 className="title">5 İşgünü İçinde Ödenecek Faturalar</h3>
                    <div className="daily-invoices">
                        <div className="daily-invoices-title">
                            <div>Ödeme Vadesi</div>
                            <div>Ödeme Tutarı</div>
                            <div>Banka</div>
                        </div>
                        <Collapse accordion defaultActiveKey={['1']}>
                            {
                                upcomingPayDate.map((dataItem :any,index:any) => {
                                   return (
                                    <Panel header={dataItem.headers.map((item:any) => <div>{item}</div>)} key={index+1} >
                                        <ul className="daily-li">
                                            {
                                                dataItem.assignedInvoices.map((item:any) => (
                                                    <li>
                                                        <span>{item.invoiceOwnerTitle}</span>
                                                        <span> {convertFloatDotSeperated(item.assigmentAmount)}</span>
                                                        <span>{item.financialInstitutionTitle}</span>                                                        
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </Panel> 
                                   )
                                })
                            }                           
                        </Collapse>
                    </div>
                </Col>      
            </Row>
            <Row>
                <Col span={12} style={{ margin: "0px auto" }}>
                    <h3 className="title"  style={{ marginTop:'20px', marginBottom:'20px'}}>Önümüzdeki 6 Ay İçerisinde Vadesi Gelecek Olan Fatura Tutarları</h3>
                    <div style={{width:'92%', height:'400px' }} className='invoice-amount-box'>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={400}
                            height={400}
                            data={dueInvoices}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 30,
                                bottom: 5,
                            }}
                        >
                        <CartesianGrid strokeDasharray="1 " />
                        <XAxis dataKey="name" />
                        <YAxis unit="k"  />
                        <Tooltip  content = {<CustomTooltipComingDue />}  />
                        <Legend />
                        <Bar dataKey="Vadesi Gelen" fill="#e7792b" />
                        </BarChart>
                    </ResponsiveContainer>
                    </div>               
                </Col>
                <Col span={12} style={{ margin: "0px auto" }}>
                    <h3 className="title"  style={{ marginTop:'20px', marginBottom:'20px'}}>Banka Limit Risk Durumu</h3>
                    <div className="limit-risk-box">
                        <div className="header">
                            <div>Banka</div>
                            <div>Limit</div>
                            <div>Risk</div>
                        </div>
                        {
                            riskLimit.map((item:any) => {
                                return (
                                    <div className="row">
                                        <div>{item.financialInstitutionName}</div>
                                        <div>{convertFloatDotSeperated(item.limit)}</div>
                                        <div>{convertFloatDotSeperated(item.risk)}</div>
                                    </div>
                                )
                            })
                        }                        
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={12} style={{ margin: "0px auto" }}>
                    <h3 className="title"  style={{ marginTop:'20px', marginBottom:'20px'}}>Son 6 Ay İçerisinde İşlem Gören Fatura Tutarları</h3>
                    <div style={{width:'92%', height:'400px' }} className='invoice-amount-box'>
                    <ResponsiveContainer width="100%" height="100%" >
                        <BarChart
                            width={400}
                            height={400}
                            data={usedInvoices}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 30,
                                bottom: 5,
                            }}
                        >
                        <CartesianGrid strokeDasharray="1 " />
                        <XAxis dataKey="namee" />
                        <YAxis unit="k" dataKey="y" />
                        <Tooltip content = {<CustomTooltipInvoices />}  />
                        <Legend />
                        <Bar dataKey="Ödenen" fill="#5ad363" />
                        <Bar dataKey="Yüklenen" fill="#1a73e8" />
                        </BarChart>
                    </ResponsiveContainer>
                    </div>               
                </Col>
                <Col span={12} style={{ margin: "0px auto" }}>
                </Col>
            </Row>
        </div>      
    </main>
  );
}

export default Dashboard;
