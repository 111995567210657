import React, { useState, useEffect } from "react";
import moment from "moment";
import { Row, Table,  Col, DatePicker, Button, notification, Select, Form, Checkbox} from "antd";
import { DownOutlined } from '@ant-design/icons';
import { getRegisteredSupplierApi, getSuppliersUsageReportApi} from "../services/supplierApi";
import { getFinanceTitlesApi} from "../services/financingApi";
import { convertFloatDotSeperated } from "../helpers/common";
import '../../node_modules/moment/locale/tr';
import locale from '../../node_modules/antd/es/date-picker/locale/tr_TR';


function SupplierUsageReport() {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [registeredSuppliers, setRegisteredSuppliers] = useState<any>([]);
  const [financialInstitutionTitles, setFinancialInstitutionTitles] = useState<any>([]);
  const [displayedValues, setDisplayedValues] = useState<string[]>([]);
  const [displayedValues1, setDisplayedValues1] = useState<string[]>([]);
  const [selectedValuesFinancial, setSelectedValuesFinancial] = useState<any>([]);
  const [selectedValuesSuppliers, setSelectedValuesSuppliers] = useState<any>([]);
  const [dashBoardSupplierUsages, setDashBoardSupplierUsages] = useState<any>([]); 
  const [resultTotal, setResultTotal] = useState<any>([]); 
  const [totalInvoicesAmountSum, setTotalInvoicesAmountSum] = useState<any>()
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [form] = Form.useForm();

  const  disabledDate = (current: any) => {  
    return (
      (new Date(current).getDay() === 0 ||
      new Date(current).getDay() === 6)
    );
  } 
 
  const getRegisteredSupplier = async () => {
    try {
       const response: any = await getRegisteredSupplierApi();
      if (response) {
        setRegisteredSuppliers(response.data.registeredSuppliers);
      } 
    } catch (e:any) {
      console.log(e)      
    }
  };

  const getFinanceTitles = async () => {
    try {
       const response: any = await getFinanceTitlesApi();
      if (response) {
        setFinancialInstitutionTitles(response.data.financialInstitutionTitles);
      } 
    } catch (e:any) {
      console.log(e)      
    }
  };

  const getSuppliersUsageReport = async (startDate:string, finishDate: string) => { 
    const supplierData = selectedValuesSuppliers.map((id:any) => `supplierIds=${id}`).join('&');
    const financialData = selectedValuesFinancial.map((id:any) => `financialInstitutionIds=${id}`).join('&');
    try {
        setLoading(true)
        const response: any = await getSuppliersUsageReportApi(startDate, finishDate, financialData, supplierData, pageNumber, pageSize);
      if (response) {
        setDashBoardSupplierUsages(response.data.dashBoardSupplierUsages.data);
        setTotalDataCount(response.data.dashBoardSupplierUsages.totalDataCount);
        const totalInvoicesAmountSum = response.data.dashBoardSupplierUsages.data.reduce((sum:any, item:any) => {
          return sum + (item.supplierUsages?.totalInvoicesAmount || 0);
        }, 0);
        setTotalInvoicesAmountSum(totalInvoicesAmountSum)
        setLoading(false)
      } 
    } catch (e:any) {
      setLoading(false)
      console.log(e)      
    }
  };

  const allValues = financialInstitutionTitles.map((item:any) => item.id.toString());
  const handleChangeFinancial = (values: string[]) => {
    setShow(false);
    setDashBoardSupplierUsages([]);
    if (values.includes("all")) {
      if (selectedValuesFinancial.length === allValues.length) {
        setSelectedValuesFinancial([]);
        setDisplayedValues1([]);
      } else {
        setSelectedValuesFinancial(allValues);
        setDisplayedValues1(["all"]);
      }
    } else {
      setSelectedValuesFinancial(values);
      setDisplayedValues1(values);
    }
  };

  const allValuesSuppliers = registeredSuppliers.map((item:any) => item.id.toString());
  const handleChangeSuppliers = (values: string[]) => {
    setShow(false);
    setDashBoardSupplierUsages([]);
    if (values.includes("all")) {
      if (selectedValuesSuppliers.length === allValuesSuppliers.length) {
        setSelectedValuesSuppliers([]);
        setDisplayedValues([]);
      } else {
        setSelectedValuesSuppliers(allValuesSuppliers);
        setDisplayedValues(["all"]);
      }
    } else {
      setSelectedValuesSuppliers(values);
      setDisplayedValues(values);
    }
  };

  const startDateChange = (val:any) => {
    setDisplayedValues([]);
    setDisplayedValues1([]);
    setEndDate('');
    setSelectedValuesFinancial([]);
    setSelectedValuesSuppliers([]);
    setShow(false);
    if(val !== null){
      setStartDate(moment(val).format('YYYY-MM-DD'))
    }else{
      setStartDate('')
    }
  }

  const endDateChange = (val:any) => {
    if(val !== null){
      setEndDate(moment(val).format('YYYY-MM-DD'))
    }else{
      setEndDate('')
    }
  }

  const onchangeValue = async () => {  
    if(Object.keys(selectedValuesSuppliers).length > 0 || Object.keys(selectedValuesFinancial).length > 0 ){
      if(startDate !== ''){
        getSuppliersUsageReport( startDate, endDate);
        setShow(true);
      }else{
        notification.warning({
          message: `Başlangınç tarihi zorunlu seçilmeli.`,
        });
        form.resetFields();
        setShow(false);
      }
    }else{
      notification.warning({
        message: `Tedarikçi ve Finans kurumu seçimi yapmalısınız.`,
      });
    }
    // eslint-disable-next-line no-console
  };
  
  const handleShowSize = (current: any, size: any) => {
    setPageNumber(current);
    setPageSize(size);
  };

const tableData = dashBoardSupplierUsages.map((item:any) => ({
  key: item.supplierUsages.id,
  supplierTitle: item.supplierUsages.title,
  financialTitles: item.supplierUsages.financialInstitutionUsages.map((fi:any) => fi.title),
  invoiceAmount: item.supplierUsages.financialInstitutionUsages.map((fi:any) => fi.totalInvoiceAmount),
  usageRate: item.supplierUsages.financialInstitutionUsages.map((fi:any) => fi.usageRate),
  financialInstitutionUsages:item.supplierUsages.financialInstitutionUsages,
}));

  const tableCols: any = [
    {
      title: "Tedarikçi Ünvanı",
      dataIndex: "supplierTitle",
      key: "supplierTitle",
      width: 150,
      fixed: 'left',
    },
    {
      title: "",
      key: "financialTitles",
      dataIndex: "financialTitles",
      width: 100,
      render: (titles: string[]) => (
        <div>
          {titles.map((title, index) => (
            <div key={index}>{title}</div>
          ))}
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>İşlem Yapılan</span>
          <span>Fatura Tutarı</span>
        </div>
      ),
      key: "invoiceAmount",
      dataIndex: "invoiceAmount",
      width: 100,
      render: (invoiceAmount: any[]) => (
        <div>
          {invoiceAmount.map((invoiceAmount, index) => (
            <div key={index}>{convertFloatDotSeperated(invoiceAmount)} TL </div>
          ))}
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>Finans Kurumu</span>
          <span>Kullanım Dağılımı</span>
        </div>
      ),
      key: "usageRate",
      dataIndex: "usageRate",
      width: 100,
      render: (usageRate: any[]) => (
        <div>
          {usageRate.map((usageRate, index) => (
            <div key={index}>{usageRate}%</div>
          ))}
        </div>
      ),
    }, 
  ];

  const calculateTotalsById = (data:any) => {
    const totals:any = {};
    data.forEach((item:any) => {
        const financialInstitutionUsages:any = item?.supplierUsages?.financialInstitutionUsages || [];
        financialInstitutionUsages.forEach((usage:any) => {
            const id = usage.id;
            if (!totals[id]) {
                totals[id] = {
                    title: usage.title,
                    totalOffer: 0,
                    usageRate: 0,
                };
            }
            totals[id].totalOffer += usage.totalInvoiceAmount;
            totals[id].usageRate += usage.usageRate;
        });
    });
    setResultTotal(totals)
    return totals;
}

useEffect(() => {
  calculateTotalsById(dashBoardSupplierUsages);
},[dashBoardSupplierUsages])

useEffect(() => {
  if(startDate){
    getSuppliersUsageReport( startDate, endDate);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [pageNumber, pageSize]);

useEffect(() => {
  getRegisteredSupplier();
  getFinanceTitles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  return (
    <main>
      <Row style={{ marginTop: 50 }}>
        <Col span={24}>
          <h3 className="page-title">Tedarikçi Kullanım Raporu</h3>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: '30px' }} span={24}>
          <Form
            form={form}
            name="filter-box"
            autoComplete="off"
            labelCol={{
              span: 8,
            }}
            size="large"
            style={{
              minWidth: 500,
            }}
          >
            <div className='filter-row suppliers-movie' style={{alignItems:'end'}}>
              <div>
                <span style={{ display: 'flex', alignItems: 'center', paddingLeft: '1px' }}>Başlangıç Tarihi<pre style={{ marginBottom: '0', color: '#f00', fontSize: '11px' }}> *</pre></span>
                <Form.Item
                  name='startDate'
                >
                  <DatePicker locale={locale}
                    format='DD/MM/YYYY'
                    placeholder='Tarih Seçiniz'
                    disabledDate={disabledDate}
                    onChange={(value) => startDateChange(value)}
                  />
                </Form.Item>
              </div>
              <div>
                <span style={{ paddingLeft: '1px' }}>Bitiş Tarihi</span>
                <Form.Item
                  name='endDate'
                >
                  <DatePicker
                    locale={locale}
                    format='DD/MM/YYYY'
                    placeholder='Tarih Seçiniz'
                    disabledDate={disabledDate}
                    onChange={(value) => endDateChange(value)}
                  />
                </Form.Item>
              </div>
            
            </div>
          </Form>
        </Col>
      </Row>    
      <Row>
        <Col span={24}>
          <p>Lütfen seçmiş olduğunuz tedarikçinin hareketlerini görüntülemek istediğiniz finans kurumunu seçiniz.</p>
          <div style={{ position: "relative", maxWidth: 500 }}>
            <Select
              mode="multiple"
              placeholder="Lütfen seçim yapınız."
              style={{ width: "100%" }}
              value={displayedValues1}
              onChange={handleChangeFinancial}
              dropdownRender={(menu) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "8px",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <Checkbox
                      indeterminate={
                        selectedValuesFinancial.length > 0 &&
                        selectedValuesFinancial.length < allValues.length
                      }
                      checked={selectedValuesFinancial.length === allValues.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedValuesFinancial(allValues);
                          setDisplayedValues1(["all"]);
                        } else {
                          setSelectedValuesFinancial([]);
                          setDisplayedValues1([]);
                        }
                      }}
                    >
                      Hepsi
                    </Checkbox>
                  </div>
                  {menu}
                </div>
              )}
              options={[
                { value: "all", label: "Hepsi" },
                ...financialInstitutionTitles.map((item: any) => ({
                  value: item.id.toString(),
                  label: (
                    <Checkbox
                      checked={selectedValuesFinancial.includes(item.id.toString())}
                      onChange={() => { }}
                    >
                      {item.name}
                    </Checkbox>
                  ),
                })),
              ]}
            />
            <div
              style={{
                position: "absolute",
                right: 10,
                top: "18px",
                transform: "translateY(-50%)",
                pointerEvents: "none",
                fontWeight: "900",
              }}
            >
              <DownOutlined />
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '30px' }} >
        <Col style={{ margin: '0 0 20px' }} span={24}>
          <p>Lütfen hareketlerini görüntülemek istediğiniz tedarikçiyi seçiniz.</p>
          <div style={{ position: "relative", maxWidth: 500 }}>
            <Select
              mode="multiple"
              placeholder="Lütfen seçim yapınız."
              style={{ width: "100%" }}
              value={displayedValues}
              onChange={handleChangeSuppliers}
              dropdownRender={(menu) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "8px",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <Checkbox
                      indeterminate={
                        selectedValuesSuppliers.length > 0 &&
                        selectedValuesSuppliers.length < allValuesSuppliers.length
                      }
                      checked={selectedValuesSuppliers.length === allValuesSuppliers.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedValuesSuppliers(allValuesSuppliers);
                          setDisplayedValues(["all"]);
                        } else {
                          setSelectedValuesSuppliers([]);
                          setDisplayedValues([]);
                        }
                      }}
                    >
                      Hepsi
                    </Checkbox>
                  </div>
                  {menu}
                </div>
              )}
              options={[
                { value: "all", label: "Hepsi" },
                ...registeredSuppliers.map((item: any) => ({
                  value: item.id.toString(),
                  label: (
                    <Checkbox
                      checked={selectedValuesSuppliers.includes(item.id.toString())}
                      onChange={() => { }}
                    >
                      {item.supplierTitle}
                    </Checkbox>
                  ),
                })),
              ]}
            />
            <div
              style={{
                position: "absolute",
                right: 10,
                top: "18px",
                transform: "translateY(-50%)",
                pointerEvents: "none",
                fontWeight: "900",
              }}
            >
              <DownOutlined />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="filter-row">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                onClick={onchangeValue}
              >Filtrele
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
      {
        show ?
        (
            <>
              <Row>
                <Col span={24} style={{ margin: "0px auto" }}>
                  <Table
                    rowKey="invoiceNumber"
                    loading={loading}
                    dataSource={tableData}
                    columns={tableCols}
                    summary={() => {
                      const titles = Object.values(resultTotal)
                        .map((details: any) => details.title)
                        .join(", ");
                      const totalOffers = Object.values(resultTotal)
                        .map((details: any) => `${convertFloatDotSeperated(details.totalOffer)} TL`)
                        .join("\n");
                      const usageRates = Object.values(resultTotal)
                        .map((details: any) => `${(details.totalOffer === 0 ? 0 : (details.totalOffer / totalInvoicesAmountSum)* 100).toFixed(2).replace(".", ",")} %`)
                        .join("\n");

                      return (
                        <Table.Summary.Row className="summary-cell-result">
                          <Table.Summary.Cell
                            index={0}
                            colSpan={1}
                          >
                            Toplam
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1}>
                            {titles.split(", ").join("\n")}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={2} >
                            {totalOffers}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={3} >
                            {usageRates}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      );
                    }}
                    pagination={{
                      position: ["topRight", "none" as any],
                      showLessItems: true,
                      showSizeChanger: true,
                      pageSize,
                      total: totalDataCount,
                      onChange: handleShowSize,

                    }}
                    scroll={{
                      x: 1200,
                    }}
                  />

                </Col>
              </Row>
            </>
        ):
        (
          ""
        )
      }

      
      
    </main>
  );
}

export default SupplierUsageReport;
